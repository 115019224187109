<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="mypwd">
        <div class="personset_con">
            <div class="personset_left">
                <p><span>*</span>输入原密码</p>
                <p><span>*</span>输入新密码</p>
                <p><span>*</span>再次输入新密码</p>
            </div>
            <div class="personset_right">
                <p><input type="password" v-model="pwd1" placeholder="请输入原登录密码"></p>
                <p><input :type="dangqian?'type':'password'" v-model="pwd2" @focus='jujiao' @blur='shijiao' placeholder='请输入6-20位英文数字混合密码'><span class="tishi animated fadeInRight" v-if="text1">请填写6-20位数字字母混合密码</span><img class="eye" :src="dangqian?require('../../assets/eye.png'):require('../../assets/eye-no.png')" @click="dangqian=!dangqian" alt=""></p>
                <p><input type="password" v-model="pwd3" @focus="jujiao_pwd3" @blur="shijiao" placeholder='请重复上述密码'><span class="tishi animated fadeInRight" v-if="text2">请重复上述密码</span></p>
            </div>
        </div>
        <div class="baocun">
            <span @click="dianji_baocun">保存</span>
        </div>
    </div>
</template>

<script>
import { alter_user_pwd } from '../../api/api.js'
export default {
  name: 'mypwdm',
  data () {
    return {
      pwd1: '',
      pwd2: '',
      pwd3: '',
      text1: false,
      text2: false,
      dangqian: false
    }
  },
  mounted () {
  },
  created () {
        
  },
  methods: {
    dianji_baocun () {
      if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd1) && /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd2) && /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd3)) {
        alter_user_pwd({
          data: {
            mobile: this.$jichuxinxi().mobile,
            pwd: this.pwd1,
            new_pwd: this.pwd2
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10012) {
            this.$message({
              message: '旧密码错误',
              type: 'warning'
            })
          } else if (res.data.code == 10063) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.$router.push('/login')
          } else if (res.data.code == 10064) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '未填完基础信息',
          type: 'warning'
        })
      }
    },
    shijiao () {
      if (this.pwd2.length != 0 && this.pwd3.length != 0) {
        if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd2))) {
          this.text1 = true
        } else {
          this.text1 = false
        }
        if (this.pwd2 === this.pwd3) {
          this.text2 = false
        } else {
          this.text2 = true
        }
      }
    },
    jujiao () {
      this.text1 = false
    },
    jujiao_pwd3 () {
      this.text2 = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './geren.scss';
</style>
